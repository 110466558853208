// logger.js

const isProduction = process.env.NODE_ENV === 'production';

/**
 * Log messages to the console.
 * @param {...any} args - The messages to log.
 * @param {string} level - The level of the log ('info', 'warn', 'error', 'debug').
 */
const log = (level = 'info', ...args) => {
    if (isProduction) {
        // In production, log only info, warn, and error
        if (level === 'debug') return; // Do not log debug messages in production
    }

    switch (level) {
        case 'debug':
            console.debug('[DEBUG]:', ...args);
            break;
        case 'info':
            console.info('[INFO]:', ...args);
            break;
        case 'warn':
            console.warn('[WARN]:', ...args);
            break;
        case 'error':
            console.error('[ERROR]:', ...args);
            break;
        default:
            console.log('[LOG]:', ...args);
    }
};

export default log;
