import React from 'react';
import './LegalDocumentsPage.css'; // Optional: for styling
import { Link } from 'react-router-dom'; // Import Link for navigation

const LegalDocuments = () => {
    return (
        <div className="legal-documents">
            {/* Sticky Navigation Bar */}
            <nav className="sticky-nav">
                <Link to="/">Back to Landing Page</Link>
            </nav>

            <h2 id="privacy">Privacy Policy</h2>
            <p>Your privacy is important to us. This Privacy Policy outlines how Radu Stochitoiu - My Life As Data collects, uses, and protects your personal information. By using our services, you consent to the data practices described in this policy.</p>

            <h3>1. Information We Collect</h3>
            <p>We collect various types of information to provide better services to all our users. This includes:</p>
            <ul>
                <li><strong>Uploaded Data:</strong> Users may upload data to our app, which we process solely for the purpose of generating personalized insights for that user.</li>
                <li><strong>Anonymized Data:</strong> Any information processed is anonymized and tailored exclusively to the individual user.</li>
                <li><strong>Usage Data:</strong> We collect general usage data about how our services are accessed, including access times and pages viewed, but do not track personal identifiers.</li>
            </ul>

            <h3>2. Data Retention and Deletion</h3>
            <p>We store anonymized data for a limited time. All uploaded information is retained for a maximum of 7 days. After this period, all data is automatically deleted, ensuring no long-term storage of personal information.</p>

            <h3>3. How We Use Your Information</h3>
            <p>Your information helps us to:</p>
            <ul>
                <li>Generate personalized insights based on your uploaded data.</li>
                <li>Improve our services by analyzing usage patterns without identifying individual users.</li>
                <li>Detect, prevent, and address technical issues and fraudulent activity.</li>
            </ul>

            <h3>4. Your Rights Under GDPR</h3>
            <p>While we strive to comply with GDPR requirements, please note the following limitations:</p>
            <ul>
                <li>Since we do not store identifiable personal information, requests for data access and portability are not applicable at this time.</li>
                <li>You have the right to request the deletion of your uploaded data at any time during the 7-day retention period.</li>
                <li>We are working on introducing a login mechanism that will enable future access rights and data portability options.</li>
            </ul>

            <h3>5. Changes to This Privacy Policy</h3>
            <p>We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. You are advised to review this Privacy Policy periodically for any changes.</p>

            <h2 id="tos">Terms of Service</h2>
            <p>By accessing or using [App Name], you agree to these Terms of Service. If you do not agree with any part of these terms, you must not use our services.</p>

            <h3>1. Acceptance of Terms</h3>
            <p>By using our services, you acknowledge that you have read, understood, and agree to be bound by these Terms. If you are using our services on behalf of a company, you represent that you have the authority to bind that company.</p>

            <h3>2. Use of Our Services</h3>
            <p>You agree to use our services only for lawful purposes and in accordance with our acceptable use policy. You must not:</p>
            <ul>
                <li>Use the service in any way that violates applicable federal, state, local, or international law or regulation.</li>
                <li>Engage in any conduct that restricts or inhibits anyone's use or enjoyment of the service, or which may harm Radu Stochitoiu - My Life As Data or users of the service.</li>
                <li>Transmit any advertising or promotional material, including any "junk mail," "chain letter," "spam," or any other similar solicitation.</li>
            </ul>

            <h3>3. Intellectual Property Rights</h3>
            <p>All content, features, and functionality on the service, including but not limited to text, graphics, logos, and software, are the exclusive property of Radu Stochitoiu - My Life As Data or its licensors and are protected by copyright, trademark, and other intellectual property laws.</p>

            <h3>4. Limitation of Liability</h3>
            <p>In no event will Radu Stochitoiu - My Life As Data, its affiliates, or their licensors, service providers, employees, agents, officers, or directors be liable for any indirect, incidental, special, consequential, or punitive damages, including but not limited to loss of profits, data, use, goodwill, or other intangible losses, arising from your use of or inability to use the service.</p>

            <h3>5. Governing Law</h3>
            <p>These Terms of Service are governed by and construed in accordance with the laws of Switzerland, without regard to its conflict of law provisions.</p>

            <h3>6. Changes to These Terms</h3>
            <p>We may revise these Terms of Service from time to time. All changes are effective immediately when we post them and apply to all access to and use of the service thereafter. Your continued use of the service following the posting of revised Terms means that you accept and agree to the changes.</p>

            <h3>7. Contact Us</h3>
            <p>If you have any questions or concerns regarding these Terms or our Privacy Policy, please contact us at radu.stochitoiu+mylifeasdata-support@gmail.com.</p>
        </div>
    );
};

export default LegalDocuments;
