import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import HeroSection from './HeroSection';
import Demo from './Demo';
import './LandingPage.css';

const LandingPage = () => {
    const navigate = useNavigate();
    const [flippedIndex, setFlippedIndex] = useState(null);
    const [loading, setLoading] = useState(true);
    const demoRef = useRef(null);

    const handleGetStartedClick = () => {
        navigate('/upload');
    };

    const scrollToDemo = () => {
        if (demoRef.current) {
            demoRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const toggleFlip = (index) => {
        setFlippedIndex(flippedIndex === index ? null : index);
    };

    const features = [
        {
            title: "Gain Clarity",
            shortDescription: "Deep insights into your data",
            longDescription: "Discover a clearer understanding of your life through profound insights derived from your data."
        },
        {
            title: "Professional Analysis",
            shortDescription: "Fast and efficient",
            longDescription: "Experience professional-grade analysis that takes very little of your time, delivering insights swiftly."
        },
        {
            title: "Free of Charge",
            shortDescription: "Totally worth a try",
            longDescription: "Take advantage of our free service and see the value for yourself without any commitment."
        },
    ];

    return (
        <div className="landing-page-container">
            {loading && (
                <div className="loading-overlay">
                    <div className="spinner"></div>
                </div>
            )}

            <div className={loading ? "hidden" : ""}>
                <HeroSection
                    onSeeDemoClick={scrollToDemo}
                    onGetStartedClick={handleGetStartedClick}
                    setLoading={setLoading}
                />
            </div>

            <section className="landing-page-features">
                {features.map((feature, index) => (
                    <div
                        key={index}
                        className={`landing-page-feature ${flippedIndex === index ? 'flipped' : ''}`}
                        onClick={() => toggleFlip(index)}
                    >
                        <div className="feature-card-front">
                            <h2 className="landing-page-feature-heading">{feature.title}</h2>
                            <p className="landing-page-feature-paragraph">{feature.shortDescription}</p>
                        </div>
                        <div className="feature-card-back">
                            <h2 className="landing-page-feature-heading">{feature.title}</h2>
                            <p className="landing-page-feature-paragraph">{feature.longDescription}</p>
                        </div>
                    </div>
                ))}
            </section>

            <Demo ref={demoRef} />
        </div>
    );
};

export default LandingPage;
