import React, { useEffect, useMemo } from 'react';
import './HeroSection.css';

// Import images
import image1 from './assets/images/netflix-roll-human/1.jpeg';
import image2 from './assets/images/netflix-roll-human/2.jpeg';
import image3 from './assets/images/netflix-roll-human/3.jpeg';
import image4 from './assets/images/netflix-roll-human/4.jpeg';
import image5 from './assets/images/netflix-roll-human/5.jpeg';
import image6 from './assets/images/netflix-roll-human/6.jpeg';
import image7 from './assets/images/netflix-roll-human/7.jpeg';
import image8 from './assets/images/netflix-roll-human/8.jpeg';
import image9 from './assets/images/netflix-roll-human/9.jpeg';
import image10 from './assets/images/netflix-roll-human/10.jpeg';
import image11 from './assets/images/netflix-roll-human/11.jpeg';
import image12 from './assets/images/netflix-roll-human/12.jpeg';
import image13 from './assets/images/netflix-roll-human/13.jpeg';
import image14 from './assets/images/netflix-roll-human/14.jpeg';
import image15 from './assets/images/netflix-roll-human/15.jpeg';
import image16 from './assets/images/netflix-roll-human/16.jpeg';
import image17 from './assets/images/netflix-roll-human/17.jpeg';
import image18 from './assets/images/netflix-roll-human/18.jpeg';
import image19 from './assets/images/netflix-roll-human/19.jpeg';
import image20 from './assets/images/netflix-roll-human/20.jpeg';
import image21 from './assets/images/netflix-roll-human/21.jpeg';
import image22 from './assets/images/netflix-roll-human/22.jpeg';
import image23 from './assets/images/netflix-roll-human/23.jpeg';
import image24 from './assets/images/netflix-roll-human/24.jpeg';
import image25 from './assets/images/netflix-roll-human/25.jpeg';
import image26 from './assets/images/netflix-roll-human/26.jpeg';
import image27 from './assets/images/netflix-roll-human/27.jpeg';
import image28 from './assets/images/netflix-roll-human/28.jpeg';
import image29 from './assets/images/netflix-roll-human/29.jpeg';
import image30 from './assets/images/netflix-roll-human/30.jpeg';

const HeroSection = ({ onSeeDemoClick, onGetStartedClick, setLoading }) => {
    const images = useMemo(() => [
        image1, image2, image3, image4,
        image5, image6, image7, image8,
        image9, image10, image11, image12,
        image13, image14, image15, image16,
        image17, image18, image19, image20,
        image21, image22, image23, image24,
        image25, image26, image27, image28,
        image29, image30,
    ], []);

    const shuffledImages = images.sort(() => Math.random() - 0.5);
    const set1 = shuffledImages.slice(0, Math.ceil(shuffledImages.length / 2));
    const set2 = shuffledImages.slice(Math.ceil(shuffledImages.length / 2));

    const createImageRoll = (set) => [...set, ...set];

    // Preload images with debugging
    useEffect(() => {
        let imagesLoaded = 0;

        const handleImageLoad = () => {
            imagesLoaded += 1;
            if (imagesLoaded === images.length) {
                setLoading(false);
            }
        };

        const handleImageError = (src) => {
            console.error(`Failed to load image: ${src}`);
            imagesLoaded += 1;
            if (imagesLoaded === images.length) {
                console.log('All images attempted (with errors)');
                setLoading(false);
            }
        };

        images.forEach((src) => {
            const img = new Image();
            img.src = src;
            img.onload = handleImageLoad;
            img.onerror = () => handleImageError(src);
        });
    }, [images, setLoading]);

    return (
        <div className="hero-section">
            <div className="hero-overlay">
                <h1 className="hero-heading">Transform Your Tracked Data Into Insights</h1>
                <p className="hero-subheading">
                    Instant analysis, clear visualizations, and expert reports at your fingertips.
                </p>
                <div className="hero-buttons">
                    <button className="hero-cta-btn" onClick={onGetStartedClick}>
                        Get Started Now
                    </button>
                    <button className="hero-cta-btn" onClick={onSeeDemoClick}>
                        See Our Demo
                    </button>
                </div>
                <p className="hero-note">No sign-up needed. Just upload your file!</p>
            </div>

            <div className="image-roll-container">
                <div className="image-roll" key={0}>
                    {createImageRoll(set1).map((image, index) => (
                        <div key={index} className="image-item">
                            <img loading='lazy' src={image} alt={`Roll: ${index + 1}`} />
                        </div>
                    ))}
                </div>
                <div className="image-roll" key={1}>
                    {createImageRoll(set2).map((image, index) => (
                        <div key={index} className="image-item">
                            <img loading='lazy' src={image} alt={`Roll: ${index + 1}`} />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default HeroSection;
