import React, { useState, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './UploadPage.css';
import DOMPurify from 'dompurify';
import logo from './assets/images/logo/logo-with-text.svg';
import talkinghead from './assets/images/talking-head.jpg';

const messages = [
    "Curious how our app works but don't have your own files? Try one of the sample files below!",
    "New here? No problem! See what we can do with one of the sample files below.",
    // additional messages...
];

const UploadPage = () => {
    const [warningMessage, setWarningMessage] = useState('');
    const [file, setFile] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [randomMessage, setRandomMessage] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        const randomIndex = Math.floor(Math.random() * messages.length);
        setRandomMessage(messages[randomIndex]);
    }, []);

    const isInvalidFileType = useCallback(
        (fileName) => fileName.split('.').pop().toLowerCase() !== 'xlsx',
        []
    );

    const handleFileChange = useCallback((event) => {
        const selectedFile = event.target.files[0];
        if (!selectedFile) {
            setWarningMessage('Please select a file!');
            setFile(null);
            return;
        }
        const sanitizedFileName = DOMPurify.sanitize(selectedFile.name);
        if (isInvalidFileType(sanitizedFileName)) {
            setWarningMessage('Invalid file type. Please upload an Excel (.xlsx) file.');
            setFile(null);
            return;
        }
        setFile(selectedFile);
        setWarningMessage('');
    }, [isInvalidFileType]);

    const uploadFile = useCallback(async () => {
        if (!file) return;
        const formData = new FormData();
        formData.append('file', file);
        setIsLoading(true);

        try {
            const response = await fetch('/api/upload', {
                method: 'POST',
                body: formData,
                credentials: 'include',
            });
            if (!response.ok) throw new Error('Failed to upload the file');
            const data = await response.json();
            if (data.error) {
                setWarningMessage(data.error);
            } else {
                const fileId = data.fileId;
                localStorage.setItem('fileId', fileId);
                navigate(`/processing`);
            }
        } catch (error) {
            console.error('Error uploading file:', error);
            setWarningMessage('Error uploading file. Please try again.');
        } finally {
            setIsLoading(false);
        }
    }, [file, navigate]);

    const handleSubmit = useCallback(
        (event) => {
            event.preventDefault();
            if (!file) {
                setWarningMessage('Please select a file!');
                return;
            }
            uploadFile();
        },
        [file, uploadFile]
    );

    const handleBackClick = () => {
        navigate('/');
    };

    return (
        <div className="upload-page-body">
            <div className="upload-page-container">
                <div className="left-section">
                    <img src={logo} alt="App Logo" />
                </div>
                <div className="right-section">
                    <h1 className="upload-page-heading">Upload Your Spreadsheet</h1>
                    <p className="upload-page-paragraph">Please select an Excel (.xlsx) file to analyze</p>
                    <form onSubmit={handleSubmit} encType="multipart/form-data" className="upload-page-form">
                        <input
                            type="file"
                            name="file"
                            accept=".xlsx"
                            onChange={handleFileChange}
                            className="upload-page-file-input"
                        />
                        <button type="submit" className="upload-page-button" disabled={isLoading}>
                            {isLoading ? 'Uploading...' : 'Upload and Process'}
                        </button>
                    </form>
                    {warningMessage && <div className="upload-page-warning-message">{warningMessage}</div>}

                    <button className="upload-page-back-button" onClick={handleBackClick}>
                        Back to Home
                    </button>
                </div>
            </div>

            <div className="talking-head-container">
                <div className="talking-head-section">
                    <p className="talking-head-text">{randomMessage}</p>
                    <div className="download-buttons">
                        <a href="/samples/basic_sample.xlsx" className="download-button" download>
                            Basic
                        </a>
                        <a href="/samples/advanced_sample.xlsx" className="download-button" download>
                            Advanced
                        </a>
                    </div>
                </div>
                <img src={talkinghead} alt="Talking Head" className="talking-head-icon" />
            </div>

            {/* Disclaimer and Example Table Section */}
            <div className="disclaimer-section">
                <h2>Important Guidelines</h2>
                <ul>
                    <li>Ensure the spreadsheet includes a column labeled <strong>'Date'</strong>.</li>
                    <li>Each row should correspond to a single day, with <strong>daily</strong> time granularity.</li>
                    <li>All other columns should represent specific attributes, metrics, or habits being tracked.</li>
                </ul>


                <h3>Example Table Format:</h3>
                <table className="example-table">
                    <thead>
                        <tr>
                            <th>Date</th>
                            <th>Steps</th>
                            <th>Water Intake (Liters)</th>
                            <th>Sleep (Hours)</th>
                            <th>Mood Score</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>2024-01-01</td>
                            <td>5000</td>
                            <td>2.0</td>
                            <td>7.5</td>
                            <td>8</td>
                        </tr>
                        <tr>
                            <td>2024-01-02</td>
                            <td>6500</td>
                            <td>1.8</td>
                            <td>6.5</td>
                            <td>7</td>
                        </tr>
                        <tr>
                            <td>2024-01-03</td>
                            <td>7000</td>
                            <td>2.2</td>
                            <td>8.0</td>
                            <td>9</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default UploadPage;
