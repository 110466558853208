import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate from react-router-dom
import './TabNavigation.css';

// Updated tabs to include the new tab with an icon
const tabs = [
    { id: 'icon-tab', label: 'Home', icon: 'favicon.ico' },
    { id: 'top3', label: 'Top Correlations' },
    { id: 'matrix', label: 'Correlation Matrix' },
    { id: 'feature-requests', label: 'Feature Requests & Bug Reports', external: true }
];

const TabNavigation = ({ activeTab, onTabChange, isFeedbackPromptVisible }) => {
    const [isCollapsed, setIsCollapsed] = useState(false);
    const navigate = useNavigate(); // Initialize navigate

    const handleToggle = () => {
        setIsCollapsed(prev => !prev);
    };

    return (
        <nav className="tab-navigation">
            <button className="tab-toggle" onClick={handleToggle}>
                {isCollapsed ? 'Show Menu' : 'Hide Menu'}
            </button>
            <ul className={`tab-navigation-tabs ${isCollapsed ? 'collapsed' : ''}`} role="tablist">
                {tabs.map(({ id, label, icon, external }) => (
                    <li key={id} className="tab-navigation-item">
                        {external ? (
                            <a
                                className={`tab-navigation-link ${activeTab === id ? 'active' : ''} ${isFeedbackPromptVisible && id === 'feature-requests' ? 'highlighted' : ''}`}
                                href="https://mylifeasdata.canny.io/feature-requests"
                                target="_blank"
                                rel="noopener noreferrer"
                                role="tab"
                                style={{ zIndex: 1001 }}
                            >
                                {label}
                            </a>
                        ) : (
                            <>
                                {/* Standalone Home Icon */}
                                {id === 'icon-tab' ? (
                                    <div
                                        className={`tab-icon ${activeTab === id ? 'active' : ''}`}
                                        aria-label="Home"
                                        onClick={() => navigate('/')} // Use navigate to change the route
                                        style={{ cursor: 'pointer' }} // Make it clear that it's clickable
                                    >
                                        <img src={`${process.env.PUBLIC_URL}/${icon}`} alt="Home" className="tab-icon" />
                                    </div>
                                ) : (
                                    <button
                                        className={`tab-navigation-link ${activeTab === id ? 'active' : ''} ${isFeedbackPromptVisible && id === 'feature-requests' ? 'highlighted' : ''}`}
                                        role="tab"
                                        onClick={() => onTabChange(id)}
                                    >
                                        {label}
                                    </button>
                                )}
                            </>
                        )}
                    </li>
                ))}
            </ul>
        </nav>
    );
};

export default TabNavigation;
