import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import './NonNumericColumns.css';

const NonNumericColumns = ({ columns, selectedColumns, onCheckboxChange, onSelectAllChange }) => {
    const [showDescription, setShowDescription] = useState(true);
    const allSelected = columns.length > 0 && columns.every(col => selectedColumns.includes(col));

    // This function handles the checkbox state change
    const handleCheckboxChange = (column) => {
        const isChecked = selectedColumns.includes(column);
        const newCheckedState = !isChecked; // Toggle the state

        // Create a synthetic event to mimic the checkbox behavior
        const event = {
            target: {
                name: `checkbox_${column}`,
                checked: newCheckedState,
            },
        };
        onCheckboxChange(event); // Call the passed down function with the synthetic event
    };

    // Toggle the description visibility
    const toggleDescription = () => {
        setShowDescription(!showDescription);
    };

    return (
        <>
            <h2 className="non-numeric-columns-heading">
                Categorical Columns
                <span onClick={toggleDescription} className="question-icon">
                    <FontAwesomeIcon icon={faQuestionCircle} />
                </span>
            </h2>

            {showDescription && (
                <p className="non-numeric-columns-paragraph">
                    Categorical columns represent distinct groups and must be preprocessed before correlation analysis. Use this checkbox list to select those columns relevant to your analysis, ensuring accurate and meaningful results.
                </p>
            )}

            {/* Standalone Select/Deselect All Button */}
            <button
                className={`non-numeric-columns-select-all-btn ${allSelected ? 'selected' : ''}`}
                onClick={() => onSelectAllChange(!allSelected)}
            >
                {allSelected ? 'Deselect All' : 'Select All'}
            </button>

            <ul className="non-numeric-columns-list-group">
                {/* Individual Column Checkboxes */}
                {columns.map((column) => (
                    <li key={column} className="non-numeric-columns-list-item" onClick={() => handleCheckboxChange(column)}>
                        <input
                            type="checkbox"
                            className="non-numeric-columns-checkbox"
                            id={`checkbox_${column}`}
                            name={`checkbox_${column}`}
                            checked={selectedColumns.includes(column)}
                            onChange={() => handleCheckboxChange(column)} // Add onChange to handle direct checkbox interaction
                        />
                        <label className="non-numeric-columns-label" htmlFor={`checkbox_${column}`} onClick={(e) => e.stopPropagation()}>
                            {column}
                        </label>
                    </li>
                ))}
            </ul>
        </>
    );
};

// Define prop types
NonNumericColumns.propTypes = {
    columns: PropTypes.arrayOf(PropTypes.string).isRequired,
    selectedColumns: PropTypes.arrayOf(PropTypes.string).isRequired,
    onCheckboxChange: PropTypes.func.isRequired,
    onSelectAllChange: PropTypes.func.isRequired,
};

export default NonNumericColumns;
