import React from 'react';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import './AboutPage.css';
import profileImage from './assets/images/about_img.jpg'; // Main profile image
import funImage from './assets/images/avatar_img.jpeg'; // Fun image for hover

const AboutPage = () => {
    return (
        <div className="about-page">
            <div className="profile-section">
                <div className="image-container">
                    <img src={profileImage} alt="Profile" className="profile-image" />
                    <img src={funImage} alt="Fun" className="hover-image" />
                </div>
            </div>
            <div className="text-section">
                <h1>About Me</h1>
                <p>
                    Hello! I’m Radu. In my spare time, I enjoy crafting fun projects that not only solve problems for myself but also have the potential to be beneficial for others.
                </p>
                <p>
                    This web application is a personal project, completely separate from my work at Google. My goal is to help individuals harness the power of their data while prioritizing their privacy and security.
                </p>
                <p>
                    I started this initiative because I believe technology should empower people to understand and utilize the information around them. Thank you for visiting my page—I’m excited to share this journey with you and hope you find value in what I’ve created!
                </p>

                {/* Styled link to navigate back to the landing page */}
                <p className="back-link">
                    <Link to="/">← Back to Landing Page</Link>
                </p>
            </div>
        </div>
    );
};

export default AboutPage;
