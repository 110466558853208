import React, { useState } from 'react';
import './FeedbackPrompt.css';

const FeedbackPrompt = ({ onClose, onNeverShowAgain }) => {
    const [neverShow, setNeverShow] = useState(false);

    const handleCheckboxChange = (e) => {
        setNeverShow(e.target.checked);
        if (e.target.checked) {
            onNeverShowAgain(true);
        }
    };

    return (
        <div className="feedback-overlay" onClick={onClose}>
            <div className="feedback-modal" onClick={(e) => e.stopPropagation()}>
                <h2>Your Feedback Matters!</h2>
                <p>
                    We genuinely care about your thoughts and experiences. Your feedback helps us build a better product that meets your needs.
                </p>
                <p>
                    If you encounter any bugs or have feature requests, please share your insights using the "Feature Requests & Bug Reports" tab in the navigation bar at the top of the page. Your input is invaluable in shaping our future improvements!
                </p>
                <label className="feedback-checkbox">
                    <input
                        type="checkbox"
                        checked={neverShow}
                        onChange={handleCheckboxChange}
                    />
                    Never show this again
                </label>
                <button onClick={onClose}>Close</button>
            </div>
        </div>
    );
};

export default FeedbackPrompt;
