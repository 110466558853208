import React, { useState } from 'react';
import './DataTable.css'; // Ensure the CSS file is properly imported

const DataTable = ({ data }) => {
    const [highlightedCell, setHighlightedCell] = useState({ row: null, col: null });

    const columns = data?.columns || [];
    const rows = data?.rows || [];
    const rowHeaders = data?.rowHeaders || [];

    const handleCellClick = (row, col) => {
        setHighlightedCell((prev) =>
            prev.row === row && prev.col === col ? { row: null, col: null } : { row, col }
        );
    };

    const getCellClass = (rowIndex, colIndex) => {
        const { row, col } = highlightedCell;
        if (row === rowIndex || col === colIndex) {
            return 'data-table-td highlighted';
        }
        if (row !== null || col !== null) {
            return 'data-table-td dimmed';
        }
        return 'data-table-td';
    };

    const getHeaderClass = (rowIndex, colIndex) => {
        const { row, col } = highlightedCell;
        if (row === rowIndex || col === colIndex) {
            return 'data-table-th highlighted';
        }
        if (row !== null || col !== null) {
            if (rowIndex === null || colIndex === null || row === rowIndex || col === colIndex) {
                return 'data-table-th dimmed';
            }
        }
        return 'data-table-th';
    };

    const formatPercentage = (val) => {
        if (val == null) return 'N/A'; // Handle null or undefined
        return `${val.toFixed(2)}%`;
    };

    const getHeatmapColor = (val) => {
        if (val === 100) return '#9fdec3';
        if (val > 70) return '#b1e2c7';
        if (val > 30) return '#c3e6cb';
        if (val > 0) return '#d4edda';
        if (val < -30) return '#e7969c';
        if (val < -70) return '#f1b0b7';
        return '#f5c6cb'; // For all other negative values
    };

    return (
        <div className="data-table-container">
            <table className="data-table-table">
                <thead>
                    <tr>
                        <th className="data-table-th empty-header"></th>
                        {columns.map((col, index) => (
                            <th key={index} className={getHeaderClass(null, index)}>
                                {col}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {rows.map((rowData, rowIndex) => (
                        <tr key={rowIndex}>
                            <th className={`data-table-th row-header ${getHeaderClass(rowIndex, null)}`}>
                                {rowHeaders[rowIndex] || ''}
                            </th>
                            {rowData.map((cellData, colIndex) => (
                                <td
                                    key={colIndex}
                                    className={getCellClass(rowIndex, colIndex)}
                                    onClick={() => handleCellClick(rowIndex, colIndex)}
                                    style={{ backgroundColor: getHeatmapColor(cellData) }}
                                >
                                    {formatPercentage(cellData)}
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default React.memo(DataTable);
