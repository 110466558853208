// ResultsPage Component
import React, { useState, useEffect, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import DataTable from './DataTable';
import TabNavigation from './TabNavigation';
import NonNumericColumns from './NonNumericColumns';
import FeedbackPrompt from './FeedbackPrompt';
import TopCorrelationsTable from './TopCorrelationsTable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import Chart from './Chart';
import './ResultsPage.css';
import ReactMarkdown from 'react-markdown';
import { fetchCorrelationData, fetchNonNumericColumns } from './api';

const ResultsPage = () => {
    // Hooks for navigation and query
    const location = useLocation();
    const navigate = useNavigate();
    const query = new URLSearchParams(location.search);
    const [fileId] = useState(query.get('fileId') || localStorage.getItem('fileId'));

    // State variables
    const [topN, setTopN] = useState(3);
    const [correlations, setCorrelations] = useState({ columns: [], rows: [], rowHeaders: [] });
    const [cappedMatrix, setCappedMatrix] = useState({ columns: [], rows: [], rowHeaders: [] });
    const [topCorrelations, setTopCorrelations] = useState([]);
    const [selectedCorrelation, setSelectedCorrelation] = useState(null);
    const [nonNumericColumns, setNonNumericColumns] = useState([]);
    const [selectedColumns, setSelectedColumns] = useState([]);
    const [activeTab, setActiveTab] = useState('top3');
    const [chartData, setChartData] = useState(null);
    const [isCappedMatrix, setIsCappedMatrix] = useState(true);
    const [showDescription, setShowDescription] = useState(false);
    const [showFeedbackModal, setShowFeedbackModal] = useState(false);
    const [neverShowFeedback, setNeverShowFeedback] = useState(false);
    const [isFeedbackPromptVisible, setIsFeedbackPromptVisible] = useState(false);
    const [allChartData, setAllChartData] = useState([]);
    const [fetchingFullCorrelationData, setFetchingFullCorrelationData] = useState(false);

    const HintMarkdown = "> **Hint:** Click on any element in the table to highlight the specific variables involved in the correlation.";

    // Toggle description visibility
    const toggleDescription = () => setShowDescription(prev => !prev);

    // Handle selection of all non-numeric columns
    const handleSelectAllChange = useCallback((isChecked) => {
        setSelectedColumns(isChecked ? nonNumericColumns : []);
    }, [nonNumericColumns]);

    // Redirect to home if fileId is not available
    useEffect(() => {
        if (!fileId) {
            navigate('/');
        } else {
            localStorage.setItem('fileId', fileId);
        }
    }, [fileId, navigate]);

    // Fetch non-numeric columns
    const fetchColumns = useCallback(async () => {
        if (!fileId) return;
        try {
            const data = await fetchNonNumericColumns(fileId);
            setNonNumericColumns(data);
        } catch (error) {
            console.error('Error fetching non-numeric columns:', error);
        }
    }, [fileId]);// Fetch full correlation data
    const fetchFullCorrelationData = useCallback(async () => {
        if (!fileId) return;
        setFetchingFullCorrelationData(true);
        try {
            const data = await fetchCorrelationData(fileId, selectedColumns);
            const {
                correlations: { columns, index, data: values },
                cappedMatrix: { columns: capped_columns, index: capped_index, data: capped_values },
                topCorrelations,
                chartData
            } = data;

            // Update state with fetched data
            setCorrelations({ columns, rows: values, rowHeaders: index });
            setCappedMatrix({ columns: capped_columns, rows: capped_values, rowHeaders: capped_index });
            setTopCorrelations(topCorrelations || []);
            setAllChartData(chartData || {});

            // Set the first correlation as selected if available
            if (topCorrelations.length > 0) {
                setSelectedCorrelation(topCorrelations[0]);

                // Combine the individual variable data for the selected correlation
                const variable1Name = topCorrelations[0].variable_1;
                const variable2Name = topCorrelations[0].variable_2;

                // Access the data for the selected variables directly from the chartData object
                const variable1Data = chartData[variable1Name];
                const variable2Data = chartData[variable2Name];

                // Combine the data for charting
                if (variable1Data && variable2Data) {
                    const combinedChartData = {
                        labels: variable1Data.labels,  // Assuming both have the same labels
                        variable1: variable1Data.data,
                        variable2: variable2Data.data,
                        variable1_name: variable1Name,
                        variable2_name: variable2Name
                    };
                    setChartData(combinedChartData);
                } else {
                    setChartData(null);  // Reset chart data if one of the variables is not found
                }
            }
        } catch (error) {
            console.error('Error fetching correlation data:', error);
        } finally {
            setFetchingFullCorrelationData(false);
        }
    }, [fileId, selectedColumns]);


    useEffect(() => {
        fetchColumns();
    }, [fetchColumns]);

    useEffect(() => {
        fetchFullCorrelationData();
    }, [fetchFullCorrelationData]);

    // Handle changes in topN value
    const handleTopNChange = useCallback((event) => {
        setTopN(Number(event.target.value));
    }, []);

    // Handle checkbox changes for selected columns
    const handleCheckboxChange = useCallback((e) => {
        const column = e.target.name.replace('checkbox_', '');
        setSelectedColumns(prev => {
            const newSelection = e.target.checked ? [...prev, column] : prev.filter(col => col !== column);
            return newSelection;
        });
    }, []);

    // Handle tab changes
    const handleTabChange = useCallback((tabId) => {
        setActiveTab(tabId);
    }, []);

    // Handle correlation item selection
    const handleCorrelationClick = useCallback((item) => {
        setSelectedCorrelation(item);

        // Access the chart data for the selected correlation directly from allChartData
        const variable1Name = item.variable_1;
        const variable2Name = item.variable_2;

        const variable1Data = allChartData[variable1Name];
        const variable2Data = allChartData[variable2Name];

        // Combine the data for charting if both variables exist
        if (variable1Data && variable2Data) {
            const combinedChartData = {
                labels: variable1Data.labels,  // Assuming both have the same labels
                variable1: variable1Data.data,
                variable2: variable2Data.data,
                variable1_name: variable1Name,
                variable2_name: variable2Name
            };
            setChartData(combinedChartData);
        } else {
            setChartData(null);  // Reset chart data if one of the variables is not found
        }
    }, [allChartData]);


    // Toggle between capped and full matrix
    const handleToggleMatrix = useCallback(() => {
        setIsCappedMatrix(prev => {
            const newState = !prev;
            if (!neverShowFeedback && Math.random() < 0.2) {
                setShowFeedbackModal(true);
                setIsFeedbackPromptVisible(true);
            }
            return newState;
        });
    }, [neverShowFeedback]);

    // Close feedback modal
    const closeFeedbackModal = () => {
        setShowFeedbackModal(false);
        setIsFeedbackPromptVisible(false);
    };

    return (
        <div className="results-container">
            <TabNavigation
                activeTab={activeTab}
                onTabChange={handleTabChange}
                isFeedbackPromptVisible={isFeedbackPromptVisible}
            />

            <div className="results-layout">
                <div className="left-side-panel">
                    {activeTab !== 'feature-requests' && (
                        <NonNumericColumns
                            columns={nonNumericColumns}
                            selectedColumns={selectedColumns}
                            onCheckboxChange={handleCheckboxChange}
                            onSelectAllChange={handleSelectAllChange}
                        />
                    )}
                </div>

                <div className="results-content">
                    <div className="tab-content mt-3">
                        {/* Top Correlations Tab */}
                        <div className={`tab-pane fade ${activeTab === 'top3' ? 'show active' : ''}`} id="top3">
                            <div className={`top-correlations-container ${fetchingFullCorrelationData ? 'greyed-out' : ''}`} >
                                <TopCorrelationsTable
                                    topCorrelations={topCorrelations}
                                    selectedCorrelation={selectedCorrelation}
                                    onCorrelationClick={handleCorrelationClick}
                                    onTopNChange={handleTopNChange}
                                    topN={topN}
                                />
                            </div>
                            <Chart
                                className="chart"
                                chartData={chartData}
                                neverShowFeedback={neverShowFeedback}
                                setShowFeedbackModal={setShowFeedbackModal}
                                setIsFeedbackPromptVisible={setIsFeedbackPromptVisible}
                            />
                        </div>

                        {/* Correlation Matrix Tab */}
                        <div className={`tab-pane fade ${activeTab === 'matrix' ? 'show active' : ''}`} id="matrix">
                            <div className="matrix-header-container">
                                <h2 className="matrix-title">
                                    Correlation Matrix
                                    <span className="question-icon" onClick={toggleDescription}>
                                        <FontAwesomeIcon icon={faQuestionCircle} />
                                    </span>
                                </h2>
                                {showDescription ? (
                                    <div className="matrix-description">
                                        <DetailedDescription />
                                    </div>
                                ) : (
                                    <SimpleDescription />
                                )}
                            </div>

                            <div className="toggle-container">
                                <label className="toggle-switch">
                                    <input type="checkbox" checked={!isCappedMatrix} onChange={handleToggleMatrix} />
                                    <span className="toggle-slider"></span>
                                </label>
                                <span>{isCappedMatrix ? 'Top Correlation Matrix' : 'Full Correlation Matrix'}</span>
                            </div>
                            <div className={`${fetchingFullCorrelationData ? 'greyed-out' : ''}`} >
                                <DataTable data={isCappedMatrix ? cappedMatrix : correlations} />
                            </div>
                            <div className="results-markdown-container">
                                <ReactMarkdown>{HintMarkdown}</ReactMarkdown>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {showFeedbackModal && <FeedbackPrompt onClose={closeFeedbackModal} onNeverShowAgain={setNeverShowFeedback} />}
        </div>
    );
};

// Detailed description of correlation interpretation
const DetailedDescription = () => (
    <div className="detailed-correlations-description">
        <p>
            This table provides a detailed view of all possible correlations between the selected variables. A correlation
            tells you how closely two things are related, ranging from -1 (a strong negative relationship) to +1
            (a strong positive relationship). This view allows you to explore the relationships between every possible pair of variables.
        </p>
        <ul>
            <li><strong>-1:</strong> A strong negative correlation. As one variable increases, the other decreases significantly.</li>
            <li><strong>0:</strong> No noticeable relationship between the two variables.</li>
            <li><strong>+1:</strong> A strong positive correlation. As one variable increases, the other increases significantly.</li>
        </ul>
        <p><strong>How to Interpret Correlation Values:</strong></p>
        <InterpretationExamples />
        <p>
            In this detailed view, you can explore how each variable in your dataset relates to every other variable. This
            provides deeper insights into the overall patterns, allowing you to discover hidden connections or unusual
            relationships that might not be immediately obvious from the top correlations alone.
        </p>
    </div>
);

// List of interpretation examples
const InterpretationExamples = () => (
    <ul>
        <li>
            <strong>0 to 0.3:</strong> Weak positive relationship – these variables are only slightly related. <br />
            <em>Example:</em> Time spent watching TV and overall happiness might have a weak correlation, meaning more TV time doesn’t significantly impact happiness.
        </li>
        <li>
            <strong>0.3 to 0.7:</strong> Moderate positive relationship – there is a noticeable link. <br />
            <em>Example:</em> Time spent exercising and overall energy levels could have a moderate correlation, meaning more exercise generally leads to feeling more energetic.
        </li>
        <li>
            <strong>0.7 to 1:</strong> Strong positive relationship – these variables are highly related. <br />
            <em>Example:</em> The number of hours you sleep and how rested you feel might have a strong correlation, meaning more sleep tends to make you feel significantly more rested.
        </li>
        <li>
            <strong>-0.3 to 0:</strong> Weak negative relationship – a small but negative link between the variables. <br />
            <em>Example:</em> Social media use and self-esteem could have a weak negative correlation, suggesting slightly more social media might slightly lower self-esteem.
        </li>
        <li>
            <strong>-0.7 to -0.3:</strong> Moderate negative relationship – there’s a noticeable but negative link. <br />
            <em>Example:</em> Increased work hours and time spent with family could have a moderate negative correlation, where more time at work often reduces time with family.
        </li>
        <li>
            <strong>-1 to -0.7:</strong> Strong negative relationship – a very strong inverse relationship. <br />
            <em>Example:</em> Stress levels and relaxation time may have a strong negative correlation, meaning more stress is strongly linked to less relaxation.
        </li>
    </ul>
);

// Simple description of correlation matrix
const SimpleDescription = () => (
    <div className="matrix-description">
        <p>
            This matrix shows the correlation between selected variables, with values ranging from -1 to +1, indicating how closely related they are.
            Click the question icon above for more details on interpreting correlation values.
        </p>
    </div>
);

export default ResultsPage;
