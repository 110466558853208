import React, { useState, forwardRef } from 'react';
import './Demo.css'; // Import the CSS for styling

const Demo = forwardRef((props, ref) => {
    const [selectedIndex, setSelectedIndex] = useState(0); // Set the initial selected index to 0

    const listItems = [
        {
            title: "Seamlessly Upload Your Data",
            description: "Easily upload your tracked data or explore our sample datasets to get started quickly and confidently."
        },
        {
            title: "Explore Key Correlations",
            description: "Discover significant correlations within your data through interactive charts that allow you to choose and compare columns effortlessly."
        },
        {
            title: "Interactive Correlation Matrix",
            description: "Gain deeper insights into your data with our advanced interactive correlation matrix, designed to help you visualize relationships between variables."
        },
        {
            title: "Guided Experience",
            description: "Embark on a journey of self-discovery with our comprehensive guidance. Each page includes helpful views to enhance your understanding and interaction with the app."
        },
    ];

    // Corresponding GIFs for each feature
    const gifs = [
        require('./assets/demo/download-sample-and-upload.gif'),
        require('./assets/demo/top-correlations-categorical-columns-and-charts.gif'),
        require('./assets/demo/correlation-matrix.gif'),
        require('./assets/demo/guidance.gif'),
    ];

    const handleItemClick = (index) => {
        setSelectedIndex(index); // Update selected index on click
    };

    return (
        <section ref={ref} className="demo-section">
            <h2 className="demo-title">See Our App In Action</h2>
            <div className="demo-content">
                <div className="demo-list">
                    <ul>
                        {listItems.map((item, index) => (
                            <li
                                key={index}
                                className={`list-item-container ${selectedIndex === index ? 'selected' : ''}`}
                                onClick={() => handleItemClick(index)} // Handle click event
                            >
                                <div className="list-item-title">{item.title}</div>
                                {selectedIndex === index && (
                                    <div className="list-item-description">{item.description}</div>
                                )}
                            </li>
                        ))}
                    </ul>
                </div>
                <div className="demo-video">
                    {/* Display the selected GIF or a default GIF if none is selected */}
                    <img
                        src={gifs[selectedIndex]}
                        alt="Demo GIF"
                        className="demo-gif"
                    />
                </div>
            </div>
        </section>
    );
});

export default Demo;
