import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { checkTaskStatus } from './api'; // Import the API function to check task status
import './ProcessingPage.css'; // Import the updated CSS

const ProcessingPage = () => {
    const navigate = useNavigate();
    const [error, setError] = useState(null);
    const [fileId, setFileId] = useState(null); // State to hold the fileId
    const [isProcessed, setIsProcessed] = useState(false); // State to track if the file is already processed
    const [processedFilePath, setProcessedFilePath] = useState(''); // Store the processed file path if provided

    useEffect(() => {
        const storedFileId = localStorage.getItem('fileId'); // Retrieve fileId from localStorage

        if (!storedFileId) {
            console.error('No fileId found in localStorage.');
            navigate('/upload'); // Redirect to UploadPage if fileId is missing
            return;
        }

        setFileId(storedFileId); // Set fileId state

        // No need to start the processing here, as it's already handled by the upload route.
    }, [navigate]);

    useEffect(() => {
        if (isProcessed) return; // If the file is already processed, skip polling

        if (!fileId) return; // If fileId is not set, do nothing

        const pollStatus = async () => {
            try {
                const statusResponse = await checkTaskStatus(fileId); // Call the API to check if the processed file exists
                if (statusResponse.status === 'complete') {
                    setIsProcessed(true); // Mark file as processed
                    setProcessedFilePath(statusResponse.processedFileUrl); // Store the processed file URL
                    navigate(`/results`); // Redirect to results page when the status is complete
                } else if (statusResponse.status === 'error') {
                    // Handle the case when there's an error in processing
                    setError('Error processing the file. Please try again.');
                }
            } catch (error) {
                console.error('Error checking task status:', error);
                setError('Error checking task status. Please try again later.');
            }
        };

        // Start the polling process
        const intervalId = setInterval(pollStatus, 2000); // Check every 2 seconds

        // Clean up the interval on component unmount
        return () => clearInterval(intervalId);
    }, [fileId, navigate, isProcessed]); // Add fileId and isProcessed as dependencies to useEffect

    return (
        <div className="processing-page-body">
            <div className="processing-page-container">
                <h1 className="processing-page-heading">Analyzing Your Data...</h1>
                <div className="processing-page-spinner"></div>
                <p className="processing-page-paragraph">
                    We are analyzing your spreadsheet to extract valuable insights. This process typically takes less than 20 seconds, but it's often even faster.
                </p>
                {error && <p className="error-message">{error}</p>} {/* Display error message if any */}
            </div>
        </div>
    );
};

export default ProcessingPage;
