// Footer.js
import React from 'react';
import './Footer.css'; // Ensure this path points correctly to your CSS file
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faFacebook,
    faTwitter,
    faInstagram,
    faLinkedin,
} from '@fortawesome/free-brands-svg-icons';
import NewsletterSubscription from './NewsletterSubscription';

const Footer = () => {
    return (
        <footer className="footer-business">
            <div className="footer-container">
                {/* Newsletter Subscription Section */}
                <div className="newsletter-container">
                    <NewsletterSubscription /> {/* Integrate subscription component */}
                </div>

                {/* Social Media Section */}
                <div className="footer-section">
                    <h4>Social Media</h4>
                    <ul>
                        <li>
                            <a
                                href="https://www.facebook.com/radusqrt"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <FontAwesomeIcon icon={faFacebook} className="footer-icon" />{' '}
                                Facebook
                            </a>
                        </li>
                        <li>
                            <a
                                href="https://www.twitter.com/radusqrt"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <FontAwesomeIcon icon={faTwitter} className="footer-icon" />{' '}
                                Twitter
                            </a>
                        </li>
                        <li>
                            <a
                                href="https://www.instagram.com/radusqrt"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <FontAwesomeIcon icon={faInstagram} className="footer-icon" />{' '}
                                Instagram
                            </a>
                        </li>
                        <li>
                            <a
                                href="https://www.linkedin.com/in/radusqrt"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <FontAwesomeIcon icon={faLinkedin} className="footer-icon" />{' '}
                                LinkedIn
                            </a>
                        </li>
                    </ul>
                </div>

                {/* Feedback Section */}
                <div className="footer-section">
                    <h4>Feedback</h4>
                    <ul>
                        <li>
                            <a
                                href="https://mylifeasdata.canny.io/feature-requests"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Feature Requests
                            </a>
                        </li>
                        <li>
                            <a
                                href="https://mylifeasdata.canny.io/feature-requests"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Bug Reports
                            </a>
                        </li>
                        <li>
                            <a href="mailto:radu.stochitoiu+mylifeasdata-support@gmail.com">Email Support</a>
                        </li>
                    </ul>
                </div>

                {/* Company Section */}
                <div className="footer-section">
                    <h4>Company</h4>
                    <ul>
                        <li>
                            <a href="/about">About Us</a>
                        </li>
                        <li>
                            <a href="/legal#privacy">Privacy Policy</a>
                        </li>
                        <li>
                            <a href="/legal#tos">Terms of Service</a>
                        </li>
                    </ul>
                </div>

                {/* Copyright Section */}
                <div className="footer-copyright">
                    &copy; {new Date().getFullYear()} Radu Stochitoiu - My Life As Data. All rights
                    reserved.
                </div>
            </div>
        </footer>
    );
};

export default Footer;
