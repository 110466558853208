import log from './logger';

export const fetchCorrelationData = async (fileId, selectedColumns) => {
    const response = await fetch('/api/full-correlation-data', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ fileId, selectedColumns }),
    });

    if (!response.ok) {
        throw new Error('Network response was not ok.');
    }

    const data = await response.json();
    log('debug', 'Fetched data: ', data);
    return data;
};

export const fetchNonNumericColumns = async (fileId) => {
    const response = await fetch(`/api/non-numeric-columns?fileId=${fileId}`);
    if (!response.ok) {
        throw new Error('Network response was not ok.');
    }
    const data = await response.json();
    log('debug', 'Fetched non-numeric columns: ', data);
    return data;
};

export const fetchTopCorrelations = async (fileId, topN, selectedColumns) => {
    const response = await fetch('/api/top-correlations', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ fileId, topN, selectedColumns }),
    });

    if (!response.ok) {
        throw new Error('Network response was not ok');
    }

    const data = await response.json();
    log('debug', 'Fetched top correlations: ', data);
    return data;
};

// New function for fetching chart data
export const fetchChartData = async (fileId, variable1, variable2) => {
    const response = await fetch('/api/chart-data', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ fileId, variable1, variable2 }),
    });

    if (!response.ok) {
        throw new Error('Network response was not ok.');
    }

    const data = await response.json();
    log('debug', 'Fetched chart data: ', data);
    return data;
};

export const subscribeToNewsletter = async (email) => {
    try {
        const response = await fetch('/api/mailchimp/subscribe', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email }), // Send only the email
        });

        if (!response.ok) {
            throw new Error(`Error subscribing to newsletter: ${response.statusText}`);
        }

        const data = await response.json();
        log('debug', 'Subscribed to newsletter: ', data);

        // Inform user about the confirmation email
        return { message: 'A confirmation email has been sent. Please check your inbox to confirm your subscription.' };
    } catch (error) {
        log('error', 'Failed to subscribe to newsletter:', error);
        throw error;
    }
};

// api.js

export const checkTaskStatus = async (fileId) => {
    const response = await fetch(`/api/task-status/${fileId}`, {
        method: 'GET',
    });
    return await response.json();
};