// src/analytics.js
export const initGA = () => {
    window.dataLayer = window.dataLayer || [];

    function gtag() {
        window.dataLayer.push(arguments);
    }

    gtag('js', new Date());
    gtag('config', process.env.REACT_APP_GA_MEASUREMENT_ID); // Use the env variable
};

export const logPageView = () => {
    window.gtag('event', 'page_view', {
        page_path: window.location.pathname,
    });
};
