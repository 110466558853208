import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import UploadPage from './UploadPage';
import ResultsPage from './ResultsPage';
import LegalDocumentsPage from './LegalDocumentsPage';
import LandingPage from './LandingPage';
import ProcessingPage from './ProcessingPage';
import Footer from './Footer'; // Import the Footer component
import { initGA, logPageView } from './analytics';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './App.css';
import AboutPage from './AboutPage';
import LogRocket from 'logrocket';
import { v4 as uuidv4 } from 'uuid'; // Import UUID to generate unique IDs

LogRocket.init('ss0hoe/my-life-as-data');

// Function to get or create a unique user ID
const getOrCreateUserId = () => {
  let userId = localStorage.getItem('user_id');
  if (!userId) {
    userId = uuidv4(); // Generate a new UUID
    localStorage.setItem('user_id', userId); // Store it in local storage
  }
  return userId;
};

function App() {
  useEffect(() => {
    const uniqueUserId = getOrCreateUserId(); // Get or create user ID
    LogRocket.identify(uniqueUserId, {
      name: 'Anonymous User', // Placeholder name
      email: '', // No email available
      userType: 'guest', // Example custom attribute
      subscriptionType: 'free' // Example if applicable
    });

    initGA(); // Initialize Google Analytics
    logPageView(); // Log the initial page view
  }, []);

  return (
    <Router>
      <RouteChangeTracker />
      <div className="app-content">
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/upload" element={<UploadPage />} />
          <Route path="/processing" element={<ProcessingPage />} />
          <Route path="/results" element={<ResultsPage />} />
          <Route path="/legal" element={<LegalDocumentsPage />} />
          <Route path="/about" element={<AboutPage />} />
        </Routes>
      </div>
      <Footer /> {/* Add the Footer component here */}
    </Router>
  );
}

const RouteChangeTracker = () => {
  const location = useLocation();

  useEffect(() => {
    logPageView(); // Log page views on route change
  }, [location]);

  return null;
};

export default App;
