// src/NewsletterSubscription.js
import React, { useState } from 'react';
import './NewsletterSubscription.css'; // New CSS file
import { subscribeToNewsletter } from './api'; // Adjust the path to your API calls file

const commonDomains = [
    'gmail.com',
    'yahoo.com',
    'hotmail.com',
    'outlook.com',
    'icloud.com',
    'aol.com',
    // Add more common domains as needed
];

const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Basic regex for email validation
    return emailRegex.test(email);
};

const isCommonDomain = (email) => {
    const domain = email.split('@')[1];
    return commonDomains.includes(domain);
};

const NewsletterSubscription = () => {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Validate email syntax
        if (!isValidEmail(email)) {
            setError('Please enter a valid email address.');
            setMessage('');
            return; // Exit if the email is invalid
        }

        // Check for common domains
        if (!isCommonDomain(email)) {
            const confirmMessage = 'The domain of your email address is uncommon. Are you sure it is correct?';
            if (!window.confirm(confirmMessage)) {
                setError('Please double-check your email address.');
                setMessage('');
                return; // Exit if the user is not sure
            }
        }

        try {
            const response = await subscribeToNewsletter(email);
            setMessage('A confirmation email has been sent. Please check your inbox to confirm your subscription.');
            setError('');
            setEmail(''); // Clear the email input
        } catch (err) {
            setError(err.message || 'An error occurred');
            setMessage('');
        }
    };

    return (
        <div className="newsletter-subscription">
            <h4>Be Part of Our Journey!</h4>
            <p>Stay updated with our latest features to help you track your data better.</p>
            <form onSubmit={handleSubmit}>
                <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Your email address"
                    required
                />
                <button type="submit">Subscribe</button>
            </form>
            {message && <p style={{ color: 'green' }}>{message}</p>}
            {error && <p style={{ color: 'red' }}>{error}</p>}
        </div>
    );
};

export default NewsletterSubscription;
